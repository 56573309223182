
import TheSidebarItem from "@/components/TheSidebarItem"
import TheSidebarProjectsList from "@/components/TheSidebarProjectsList"
import TheSidebarCamerasList from "@/components/TheSidebarCamerasList"
import { camelizeKeys } from "humps"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { _3dViewer, InfoPage } from "@evercam/shared/types"
import { BIM_VIEWER_TYPES } from "@evercam/shared/constants/3d"

export default {
  name: "TheLeftSideBar",
  components: {
    TheSidebarItem,
    TheSidebarProjectsList,
    TheSidebarCamerasList,
  },
  data() {
    return {
      listHeight: 0,
      title: "Evercam",
      tutorialsClicked: false,
      userChannel: null,
      projectChannel: null,
      viewer: _3dViewer,
      bimViewerTypes: BIM_VIEWER_TYPES,
      isHoveringSidebar: false,
    }
  },
  computed: {
    _360View() {
      return this.$permissions.project.has._360View()
    },
    ...mapStores(
      useLayoutStore,
      useProjectStore,
      useCameraStore,
      useAccountStore
    ),
    listStyle() {
      return `
        max-height: ${this.listHeight}px;
      `
    },
    routeProjectExid() {
      return camelizeKeys(this.$route.params)?.projectExid
    },
    isProjectView() {
      return !!this.routeProjectExid
    },
    projectName() {
      return (
        this.projectStore.selectedProjectName ||
        this.cameraStore.selectedCamera?.project?.name
      )
    },
    isVideoWallsEnabled() {
      return this.$permissions.user.can.access.videoWalls()
    },
    mapURL() {
      if (this.routeProjectExid) {
        return `${this.projectStore.projectRoute}/map`
      } else {
        return "/v2/map"
      }
    },
    projectRoute() {
      return `/v2/projects/${this.routeProjectExid}`
    },
    gateReportInfoPageUrl() {
      return `${this.projectRoute}/info/${InfoPage.GateReport}`
    },
    BIMViewInfoPageUrl() {
      return `${this.projectRoute}/info/${InfoPage.BIMView}`
    },
    _360ViewInfoPageUrl() {
      return `${this.projectRoute}/info/${InfoPage.ThreeSixtyView}`
    },
    droneViewInfoPageUrl() {
      return `${this.projectRoute}/info/${InfoPage.DroneView}`
    },
    bimCompareInfoPageUrl() {
      return `${this.projectRoute}/info/${InfoPage.BimCompare}`
    },
    timelineInfoPageUrl() {
      return `${this.projectRoute}/info/${InfoPage.Timeline}`
    },
  },
  watch: {
    isProjectView: {
      async handler() {
        await this.projectStore.fetchProjectTPulseLink()
        this.$setTimeout(() => {
          this.updateLeftColumnHeight()
        }, 200)
      },
      immediate: true,
    },
    "projectStore.selectedProject": {
      handler(value) {
        if (value) {
          this.initProjectChannel(value)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$setTimeout(() => {
      this.updateLeftColumnHeight()
    }, 2000)
  },
  created() {
    this.layoutStore.isLeftSidebarVisible =
      this.layoutStore.isPermanentLeftSidebar
    this.initSocket()
  },
  methods: {
    updateLeftColumnHeight() {
      let backButtonHeight =
        document.getElementById("backButton")?.clientHeight || 0
      let projectFeaturesHeight =
        document.getElementById("projectFeatures")?.clientHeight || 0
      let leftBottomMenuHeight =
        document.getElementById("bottomMenu")?.clientHeight || 0
      let leftTopMenuHeight =
        document.getElementById("topMenu")?.clientHeight || 0
      let leftFooterHeight =
        document.getElementById("footer")?.clientHeight || 0
      let leftHeaderHeight =
        document.getElementById("header")?.clientHeight || 0
      let heights =
        window.innerHeight -
        leftBottomMenuHeight -
        leftTopMenuHeight -
        leftHeaderHeight -
        leftFooterHeight -
        projectFeaturesHeight -
        35
      this.listHeight =
        this.isProjectView && this.$device.isMobile
          ? heights - backButtonHeight
          : heights
    },
    onMediaHubClicked(e) {
      this.cameraStore.selectedCamera = null
      this.onItemClicked(e)
    },
    onItemClicked(e = {}) {
      const href = e.path?.find((el) => el.tagName === "A")?.href
      const isSameRoute = href && href === document.location.href
      if (isSameRoute) {
        return
      }

      if (document.getElementById("zohohc-asap-web-layoutbox")) {
        document.getElementById("zohohc-asap-web-layoutbox").style.display =
          "none"
        window.ZohoHCAsap?.Logout()
      }
    },
    async initSocket() {
      if (this.accountStore.isUserOffline) {
        return
      }
      this.userChannel = this.$websocket.channel(
        this.$config.public.socketURL,
        `users:${this.accountStore.email}`
      )

      this.userChannel?.on("camera-share", () => {
        this.projectStore.fetchUserProjects()
        this.cameraStore.fetchUserCameras()
      })
    },
    async initProjectChannel(selectedProject) {
      if (this.accountStore.isUserOffline) {
        return
      }

      this.projectChannel = this.$websocket.channel(
        this.$config.public.socketURL,
        `projects:${selectedProject?.id}`
      )
      this.projectChannel?.on("camera-status-update", (changes) => {
        if (changes.length) {
          changes.forEach((change) => {
            this.cameraStore.updateCameraProperty({
              modifiedCamera: change,
              modifiedProperty: "status",
            })
          })
        } else {
          this.cameraStore.updateCameraProperty({
            modifiedCamera: changes,
            modifiedProperty: "status",
          })
        }
      })

      this.projectChannel?.on("timelapse", (mediaItem) => {
        this.$root.$emit(
          "timelapse-creation-completed",
          camelizeKeys(mediaItem)
        )
      })
    },
    beforeDestroy() {
      this.$websocket.disconnect()
    },
    getItemContentColor(isActive) {
      if (isActive) {
        return this.$vuetify.theme.currentTheme.primary
      }

      return this.$vuetify.theme.currentTheme.on_background
    },
    onVideoWallsClicked(e) {
      if (!this.$permissions.project.has.go()) {
        this.$fullscreen.toggle(this.$globalRefs.mainContainer)
      }
      this.onItemClicked(e)
    },
    onMouseEnter() {
      this.isHoveringSidebar = true
      this.$setTimeout(() => {
        if (
          this.isHoveringSidebar &&
          this.layoutStore.isLeftSidebarMinimized &&
          !this.layoutStore.isLeftSidebarPersistent
        ) {
          this.layoutStore.toggleIsLeftSidebarMinimized()
        }
      }, 300)
    },
    onMouseLeave() {
      this.isHoveringSidebar = false
      if (
        !this.layoutStore.isLeftSidebarMinimized &&
        !this.layoutStore.isLeftSidebarPersistent
      ) {
        this.layoutStore.toggleIsLeftSidebarMinimized()
      }
    },
  },
}
